var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "card" }, [
    _c("div", { staticClass: "card__body" }, [
      _c("div", { staticClass: "selector" }, [
        _c(
          "div",
          {
            staticClass: "physics",
            class: { active: _vm.activeButton == 0 },
            on: {
              click: function ($event) {
                _vm.activeButton = 0
              },
            },
          },
          [_c("span", [_vm._v("Arquivos")])]
        ),
        _c(
          "div",
          {
            staticClass: "legal",
            class: { active: _vm.activeButton == 1 },
            on: {
              click: function ($event) {
                _vm.activeButton = 1
              },
            },
          },
          [_c("span", [_vm._v("Templates")])]
        ),
      ]),
      _c(
        "div",
        { staticClass: "card__body__items" },
        [
          _vm._l(_vm.infos, function (element) {
            return [
              _vm.activeButton == 0 && element.type == "file"
                ? _c("div", { key: element.id, staticClass: "element" }, [
                    _c("span", { staticClass: "element__title" }, [
                      _vm._v(" " + _vm._s(element.title)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "element__childs mt-2" },
                      [
                        _vm._l(element.childs, function (child) {
                          return [
                            !child.is_to_create_new_info
                              ? _c(
                                  "div",
                                  {
                                    key: child.id,
                                    staticClass: "child normal",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "normal__header" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "left d-flex align-center",
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#202123" } },
                                              [_vm._v(" $star_outline")]
                                            ),
                                            _c("span", [
                                              _vm._v(_vm._s(child.title)),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "right" },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#202123" } },
                                              [_vm._v("mdi-dots-vertical")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "normal__body" }, [
                                      _c("figure"),
                                      _c("div", { staticClass: "infos" }, [
                                        child.created
                                          ? _c(
                                              "div",
                                              { staticClass: "item" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("mdi-calendar ")]
                                                ),
                                                _c("span", [
                                                  _vm._v("Criado em: "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(child.created)
                                                    ),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        child.users
                                          ? _c(
                                              "div",
                                              { staticClass: "item" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("$zoox_user ")]
                                                ),
                                                _c("span", [
                                                  _vm._v("Usuários únicos: "),
                                                  _c("strong", [
                                                    _vm._v(_vm._s(child.users)),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        child.connection
                                          ? _c(
                                              "div",
                                              { staticClass: "item" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "mdi-ethernet-cable "
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v("Conexão: "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(child.connection)
                                                    ),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    key: child.id,
                                    staticClass: "child new-info",
                                  },
                                  [
                                    _c("figure"),
                                    _c("span", [_vm._v(_vm._s(child.title))]),
                                  ]
                                ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm.activeButton == 1 && element.type == "template"
                ? _c("div", { key: element.id, staticClass: "element" }, [
                    _c("span", { staticClass: "element__title" }, [
                      _vm._v(" " + _vm._s(element.title)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "element__childs mt-2" },
                      [
                        _vm._l(element.childs, function (child) {
                          return [
                            !child.is_to_create_new_info
                              ? _c(
                                  "div",
                                  {
                                    key: child.id,
                                    staticClass: "child normal",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "normal__header" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "left d-flex align-center",
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#202123" } },
                                              [_vm._v(" $star_outline")]
                                            ),
                                            _c("span", [
                                              _vm._v(_vm._s(child.title)),
                                            ]),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "right" },
                                          [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "#202123" } },
                                              [_vm._v("mdi-dots-vertical")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c("div", { staticClass: "normal__body" }, [
                                      _c("figure"),
                                      _c("div", { staticClass: "infos" }, [
                                        child.created
                                          ? _c(
                                              "div",
                                              { staticClass: "item" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("mdi-calendar ")]
                                                ),
                                                _c("span", [
                                                  _vm._v("Criado em: "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(child.created)
                                                    ),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        child.users
                                          ? _c(
                                              "div",
                                              { staticClass: "item" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [_vm._v("$zoox_user ")]
                                                ),
                                                _c("span", [
                                                  _vm._v("Usuários únicos: "),
                                                  _c("strong", [
                                                    _vm._v(_vm._s(child.users)),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        child.connection
                                          ? _c(
                                              "div",
                                              { staticClass: "item" },
                                              [
                                                _c(
                                                  "v-icon",
                                                  { attrs: { small: "" } },
                                                  [
                                                    _vm._v(
                                                      "mdi-ethernet-cable "
                                                    ),
                                                  ]
                                                ),
                                                _c("span", [
                                                  _vm._v("Conexão: "),
                                                  _c("strong", [
                                                    _vm._v(
                                                      _vm._s(child.connection)
                                                    ),
                                                  ]),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "div",
                                  {
                                    key: child.id,
                                    staticClass: "child new-info",
                                  },
                                  [
                                    _c("figure"),
                                    _c("span", [_vm._v(_vm._s(child.title))]),
                                  ]
                                ),
                          ]
                        }),
                      ],
                      2
                    ),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
    _c(
      "div",
      { staticClass: "card__footer d-flex justify-end" },
      [
        _c("z-btn", {
          attrs: { text: "Fechar", small: "", rounded: "", color: "#202123" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }