var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "items" },
    [
      _vm.items.length
        ? _vm._l(_vm.items, function (item, index) {
            return _c(
              "button",
              {
                key: index,
                staticClass: "item",
                class: { "is-selected": index === _vm.selectedIndex },
                on: {
                  click: function ($event) {
                    return _vm.selectItem(index)
                  },
                },
              },
              [_vm._v(" " + _vm._s(item) + " ")]
            )
          })
        : _c("div", { staticClass: "item" }, [
            _vm._v("Consulta não encontrada"),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }