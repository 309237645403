var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "card" }, [
    _c("div", { staticClass: "card__header" }, [
      _c("div", { staticClass: "title" }, [_c("span", [_vm._v("Zoox AI")])]),
      _c(
        "div",
        { staticClass: "action" },
        [
          _c(
            "v-icon",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("mdi-close")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "card__descriptions" }, [
      _c("span", [
        _c("strong", [_vm._v("A Inteligência Artificial da Zoox")]),
        _vm._v(" tem como propósito "),
        _c("strong", [_vm._v("facilitar")]),
        _vm._v(" e "),
        _c("strong", [_vm._v("obter")]),
        _vm._v(" os "),
        _c("strong", [_vm._v("melhores dados")]),
        _vm._v(" para sua empresa. "),
        _c("br"),
        _vm._v(" As iniciativas de IA são assentes em "),
        _c("strong", [_vm._v("3 pilares")]),
        _vm._v(" aliados a "),
        _c("strong", [_vm._v("facilidade de uso")]),
        _vm._v(" das mesmas. "),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "card__filter" },
      [
        _c("span", [
          _c("strong", [_vm._v("Escolha")]),
          _vm._v(" uma opção para "),
          _c("strong", [_vm._v("começa.")]),
        ]),
        _c("z-input", {
          staticClass: "my-2",
          attrs: {
            placeholder: "Pesquise uma ação",
            prependIcon: "$search_rounded",
            type: "text",
            hideDetails: true,
            small: "",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "card__lists" },
      _vm._l(_vm.infos, function (item) {
        return _c("div", { key: item.id, staticClass: "item-info" }, [
          _c("div", { staticClass: "item-info__title mb-3" }, [
            _c("strong", [_vm._v(_vm._s(item.title))]),
          ]),
          _c(
            "div",
            { staticClass: "item-info__childs-list" },
            _vm._l(item.childs, function (child) {
              return _c(
                "div",
                {
                  key: child.name,
                  staticClass: "child",
                  on: {
                    click: function ($event) {
                      return _vm.openSelectedCard(child)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "child__title" }, [
                    _c("span", [_vm._v(" " + _vm._s(child.name))]),
                  ]),
                  _c("div", { staticClass: "child__description" }, [
                    _vm._v(" " + _vm._s(child.description) + " "),
                  ]),
                ]
              )
            }),
            0
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }