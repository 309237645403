var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "card" }, [
    _c("div", { staticClass: "card__header" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("Nova Coluna De Uma Fórmula")]),
      ]),
      _c(
        "div",
        { staticClass: "action" },
        [
          _c(
            "v-icon",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("mdi-close")]
          ),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "card__body" }, [
      _c("div", { staticClass: "left" }, [
        _c("div", { staticClass: "left__description mb-2" }, [
          _c("span", [_vm._v("Descreva como você quer sua formula")]),
        ]),
        _c("div", { staticClass: "left__square-formula" }, [
          _c(
            "div",
            { staticClass: "formula-section" },
            [
              _c("Prompt", {
                attrs: { "is-to-enable-prompt": true },
                scopedSlots: _vm._u([
                  {
                    key: "action",
                    fn: function (props) {
                      return [
                        _c("z-btn", {
                          attrs: {
                            text: "Gerar fórmula",
                            "x-small": "",
                            rounded: "",
                            color: "#202123",
                          },
                          on: { click: props.onClick },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "text",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex align-center" },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { "x-small": "" } },
                                          [_vm._v("mdi-code-braces")]
                                        ),
                                        _c("span", [_vm._v("Gerar fórmula")]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "left__description mb-2" }, [
          _c("span", [_vm._v("Digite / para incluir uma coluna")]),
          _c("br"),
          _c("span", [_vm._v("Ou escreva sua própria fórmula")]),
        ]),
        _c(
          "div",
          { staticClass: "left__square-formula" },
          [
            _c("Prompt", {
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function (props) {
                    return [
                      _c("z-btn", {
                        attrs: {
                          text: "Executar fórmula",
                          "x-small": "",
                          rounded: "",
                          color: "#202123",
                        },
                        on: { click: props.onClick },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "text",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { "x-small": "" } },
                                        [_vm._v("$play_circle_outline")]
                                      ),
                                      _c("span", [_vm._v("Executar fórmula")]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      !_vm.hasFormula
        ? _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "right__title mb-2" }, [
              _c("strong", [_vm._v("Exemplos de Prompts")]),
            ]),
            _c(
              "div",
              { staticClass: "right__list" },
              [
                _vm._l(_vm.prompts, function (item, i) {
                  return [
                    _c("div", { key: item, staticClass: "item" }, [
                      _c("span", [
                        _c("strong", [_vm._v(_vm._s(i + 1) + " -")]),
                        _vm._v(" " + _vm._s(item)),
                      ]),
                    ]),
                  ]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
      _vm.hasFormula
        ? _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "right__title mb-2" }, [
              _c("strong", [_vm._v("Resultado da fórmula")]),
            ]),
            _c(
              "div",
              { staticClass: "right__table" },
              [
                _c("z-table", {
                  staticClass: "table",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.users_result_mock,
                    "icon-next": "mdi-chevron-right",
                    "icon-previous": "mdi-chevron-left",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "card__footer d-flex justify-end" },
      [
        _c("z-btn", {
          attrs: { text: "Cancelar", small: "", rounded: "", color: "#202123" },
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }