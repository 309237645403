var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "card__header mb-2" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", [_vm._v("Qualificação")]),
        ]),
        _c(
          "div",
          { staticClass: "action" },
          [
            _c(
              "v-icon",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("mdi-close")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "card__body" },
        [_c(_vm.components_list[_vm.currentStepIndex], { tag: "component" })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "card__footer d-flex align-center justify-end mt-3",
          staticStyle: { gap: "0.5rem" },
        },
        [
          _c("z-btn", {
            attrs: {
              text: "Cancelar",
              small: "",
              rounded: "",
              color: "#202123",
            },
          }),
          _c("z-btn", {
            staticStyle: { color: "#fff" },
            attrs: {
              text: _vm.buttonTitle,
              rounded: "",
              small: "",
              primary: "",
              color: "#202123",
            },
            on: { click: _vm.goSteps },
          }),
        ],
        1
      ),
      _c("z-card-modal", {
        attrs: { title: "", width: "450" },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "info-modal" }, [
                  _c("div", { staticClass: "title mb-4" }, [
                    _c("span", [_vm._v("Criação De Colunas Qualificadas")]),
                  ]),
                  _c("div", { staticClass: "drescription" }, [
                    _c("p", [
                      _vm._v(" Setão criadas "),
                      _c("strong", [_vm._v("2 novas colunas")]),
                      _vm._v(" com dados: "),
                      _c("strong", [_vm._v('"E-mail" + "Telefone"')]),
                    ]),
                    _c("p", [
                      _vm._v("Total de créditos a serem usados: "),
                      _c("strong", [_vm._v("1000 créditos.")]),
                    ]),
                    _c("p", [_vm._v("Deseja prosseguir?")]),
                    _c(
                      "div",
                      {
                        staticClass: "actions d-flex justify-end",
                        staticStyle: { gap: "0.5rem" },
                      },
                      [
                        _c("z-btn", {
                          attrs: {
                            text: "Cancelar",
                            small: "",
                            rounded: "",
                            color: "#202123",
                          },
                          on: { click: _vm.closeConfirmation },
                        }),
                        _c("z-btn", {
                          staticStyle: { color: "#fff" },
                          attrs: {
                            text: "Sim",
                            rounded: "",
                            small: "",
                            primary: "",
                            color: "#202123",
                          },
                          on: { click: _vm.closeConfirmation },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.confirmationDialog,
          callback: function ($$v) {
            _vm.confirmationDialog = $$v
          },
          expression: "confirmationDialog",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }