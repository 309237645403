var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-three" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "drag-n-drop-zone" },
      [
        _c(
          "draggable",
          { attrs: { list: _vm.providers, tag: "div", handle: ".handle" } },
          _vm._l(_vm.providers, function (item, i) {
            return _c(
              "div",
              { key: item.id, staticClass: "list-item" },
              [
                _c(
                  "v-icon",
                  { staticClass: "handle", staticStyle: { cursor: "move" } },
                  [_vm._v(" $move_element")]
                ),
                _c("z-autocomplete", {
                  attrs: {
                    items: ["a", "b", "c", "d"],
                    hideDetails: true,
                    label: `${i + 1}ª  Opção`,
                    small: "",
                    type: "text",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "new my-3" },
          [
            _c(
              "span",
              {
                staticStyle: { cursor: "pointer" },
                on: { click: _vm.addNewprovider },
              },
              [_vm._v("+ Adicionar novo")]
            ),
            _c("v-divider", { staticClass: "my-3" }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "schedules" }, [
      _vm._m(1),
      _c(
        "div",
        {
          staticClass: "switch d-flex align-center my-3",
          staticStyle: { gap: "0.5rem" },
        },
        [
          _c("span", [_vm._v("Automático")]),
          _vm._v(" "),
          _c("v-switch", { attrs: { "hide-details": true } }),
          _vm._v(" Agendado "),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "recurring" },
        [
          _c("z-autocomplete", {
            attrs: {
              items: ["a", "b", "c", "d"],
              hideDetails: true,
              label: "Recorrência",
              small: "",
              type: "text",
              readonly: "",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descriptions" }, [
      _c("p", [
        _vm._v(
          " Defina os provedores selecionando as opções abaixo, na ordem de prioridade de enriquecimento "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "schedules__text" }, [
      _c("span", [
        _vm._v(" Você pode definir se quer "),
        _c("strong", [_vm._v("agendar")]),
        _vm._v(
          " ou não essa qualificação. Caso agende, você receberá uma notificação a cada nova qualificação realizada nessa base. "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }