<template>
  <v-card class="card">
    <div class="card__header">
      <div class="title">
        <span>Use um template de AI</span>
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>
    <div class="card__filter">
      <z-input
        small
        placeholder="Pesquise uma ação"
        prependIcon="$search_rounded"
        type="text"
        :hideDetails="true"
      />
    </div>

    <div class="card__body">
      <div v-for="item in infos" :key="item.id" class="card__body__items">
        <div class="card__body__items__title">
          <strong>{{ item.title }}</strong>
        </div>

        <div class="card__body__items__childs_list">
          <div
            v-for="child in item.childs"
            :key="child.name"
            class="child"
            @click="$emit('close')"
          >
            <div class="child__title">
              <strong>{{ child.name }}</strong>
            </div>
            <div class="child__description">
              <span>{{ child.description }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      infos: [
        {
          title: "Exemplos sobre Pessoas",
          id: 0,
          childs: [
            {
              name: "Link do Linkedin",
              description:
                "Encontrar o link do Linkedin de [nome da pessoa] que trabalha na [nome da empresa]",
            },
            {
              name: "Encontrar o Cardo das pessoas",
              description:
                "Identificar o cargo atual de [nome da pessoa], que trabalha na [nome da empresa]",
            },
            {
              name: "Compilar Links",
              description:
                "Compular links das principais redes sociais(Linkedin, Facebook, Instagram, TikTok) de [nome da pessoa] da [noem da empresa]",
            },
          ],
        },
        {
          title: "Exemplos sobre Empresas",
          id: 2,
          childs: [
            {
              name: "Empresa em processo de abertura",
              description:
                "Verificar se [nome da empresa] está atualmente em processo de abertura ou fechamento de suas lojas",
            },
            {
              name: "Obter CNPJ da empresa",
              description: "Obter o número do CNPJ associado à [nome da empresa]",
            },
            {
              name: "Quantidade de lojas",
              description:
                "Determinar a quantidade total de lijas que a [nome da empresa] possui na [localidade]",
            },
            {
              name: "Horário de funcionamento",
              description:
                "Checar se a loja específica da [nome da empresa] em [endereço ou localidade] está aberta e qual o seu horário de funcionamento",
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.card {
  padding: 1rem;
  height: 80vh;
  height: 90dvh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 0.92rem;
  overflow: hidden;
  font-size: 0.88rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    flex: 1;
    overflow-y: auto;
    @include trackScrollBar;
    &__items {
      padding: 0 0.3rem 0.5rem;
      &__childs_list {
        display: grid;
        gap: 0.8rem;
        grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

        .child {
          padding: 0.5rem;
          border-radius: 10px;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        }
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
