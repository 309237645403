<template>
  <v-card class="card">
    <div class="card__header">
      <div class="title">
        <span>Scrapper AI</span>
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>

    <div class="card__body">
      <div class="card__body__description">
        <span
          >Escreva uma pergunta para nosso assistente, usando os inputs disponíveis para
          gerar uma resposta e realizar seu objetivo.</span
        >
      </div>

      <div class="card__body__prompt">
        <div class="action">
          <span>Comece com um template de integração (opcional)</span>
          <br />
          <z-btn
            text="Selecionar template"
            x-small
            rounded
            color="#202123"
            class="mt-3"
            @click="templatesModalDialog = true"
          >
            <template #text>
              <div class="d-flex align-center">
                <v-icon x-small>mdi-plus</v-icon>
                <span>Selecionar template</span>
              </div>
            </template>
          </z-btn>
        </div>

        <!-- <div class="element my-3"></div> -->
        <Prompt :is-to-enable-prompt="true" class="element my-3" />

        <div class="description">
          <span> Digite / para incluir uma coluna</span>
        </div>

        <div class="action mt-3">
          <span>Dê exemplos dos resultados esperados (opcional)</span>
          <br />
          <z-btn
            text="Adicionar Exemplo"
            x-small
            rounded
            color="#202123"
            class="mt-3"
            @click="zooxAiModalDialog = true"
          >
            <template #text>
              <div class="d-flex align-center">
                <v-icon x-small>mdi-plus</v-icon>
                <span>Adicionar exemplo</span>
              </div>
            </template>
          </z-btn>
        </div>
      </div>

      <div class="card__body__models">
        <div class="checkboxes">
          <div class="d-flex align-center justify-space-between mb-2">
            <z-checkbox label="Modelos AI" :hide-details="true" />

            <!-- <v-chip pill>
              <v-icon small>mdi-close-outline</v-icon>
              1
            </v-chip> -->
            <z-chip color="#98CBE8" icon="mdi-cash" text="1" />
          </div>
          <div class="d-flex align-center justify-space-between">
            <z-checkbox label="Usar seu modelo" :hide-details="true" />
            <z-chip text="Grátis" />
          </div>
        </div>
      </div>

      <div class="card__body__ias">
        <div
          v-for="item in ai_list"
          :key="item.name"
          class="ia"
          :class="{ active: activeIA == item.id }"
          @click="activeIA = item.id"
        >
          <div class="left">
            <figure></figure>
            <span>{{ item.name }}</span>
          </div>
          <div class="right">
            <div class="circle">
              <v-icon v-if="activeIA == item.id" small color="#0273c3">mdi-check</v-icon>
            </div>
          </div>
        </div>
      </div>

      <v-divider class="my-2"></v-divider>

      <div class="card__body__responses">
        <z-select
          small
          label="Tipo de resposta"
          class="mb-2"
          :items="['a', 'b', 'c']"
          :hide-details="true"
        />

        <z-checkbox label="Automatização automatica" :hide-details="true" />
      </div>
    </div>

    <v-dialog
      v-model="templatesModalDialog"
      width="900"
      style="border-radius: 10px !important"
    >
      <TemplatesAIModal
        v-if="templatesModalDialog"
        @close="templatesModalDialog = false"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import TemplatesAIModal from "./templatesAiModal.vue";
import Prompt from "../shared/Prompt.vue";
export default {
  components: {
    TemplatesAIModal,
    Prompt,
  },
  data() {
    return {
      activeIA: null,
      templatesModalDialog: false,
      ai_list: [
        {
          name: "GPT 3.5",
          img_path: "",
          id: 0,
        },
        {
          name: "GPT 4 TURBO",
          img_path: "",
          id: 1,
        },
        {
          name: "Gemini",
          img_path: "",
          id: 3,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.card {
  padding: 1rem;
  height: 80vh;
  height: 90dvh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 0.92rem;
  overflow: hidden;
  font-size: 0.88rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
    @include trackScrollBar2;
    padding: 0 0.2rem 0.5rem;
    gap: 1rem;
    &__prompt {
      .action {
      }

      .element {
        border: 1px solid;
        border-radius: 15px;
        height: 25vh;
      }
    }
    &__models {
    }

    &__ias {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
      gap: 0.5rem;

      .ia {
        flex: 1 1 200px;
        padding: 0.5rem;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
        display: flex;
        border-radius: 15px;
        cursor: pointer;

        .left {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.3rem;
          figure {
            background: #cdcdcd;
            width: 50px;
            height: 50px;
            border-radius: 50%;
          }

          span {
            font-size: 0.6rem;
          }
        }

        .right {
          .circle {
            border: 2px solid;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .active {
        background: #0273c3;
        color: white;

        .circle {
          background: #fff;
        }
      }
    }
  }
}
</style>
