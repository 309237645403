var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "card" },
    [
      _c("div", { staticClass: "card__header" }, [
        _c("div", { staticClass: "title" }, [
          _c("span", [_vm._v("Scrapper AI")]),
        ]),
        _c(
          "div",
          { staticClass: "action" },
          [
            _c(
              "v-icon",
              {
                on: {
                  click: function ($event) {
                    return _vm.$emit("close")
                  },
                },
              },
              [_vm._v("mdi-close")]
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "card__body" },
        [
          _c("div", { staticClass: "card__body__description" }, [
            _c("span", [
              _vm._v(
                "Escreva uma pergunta para nosso assistente, usando os inputs disponíveis para gerar uma resposta e realizar seu objetivo."
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "card__body__prompt" },
            [
              _c(
                "div",
                { staticClass: "action" },
                [
                  _c("span", [
                    _vm._v("Comece com um template de integração (opcional)"),
                  ]),
                  _c("br"),
                  _c("z-btn", {
                    staticClass: "mt-3",
                    attrs: {
                      text: "Selecionar template",
                      "x-small": "",
                      rounded: "",
                      color: "#202123",
                    },
                    on: {
                      click: function ($event) {
                        _vm.templatesModalDialog = true
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-plus"),
                                ]),
                                _c("span", [_vm._v("Selecionar template")]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c("Prompt", {
                staticClass: "element my-3",
                attrs: { "is-to-enable-prompt": true },
              }),
              _c("div", { staticClass: "description" }, [
                _c("span", [_vm._v(" Digite / para incluir uma coluna")]),
              ]),
              _c(
                "div",
                { staticClass: "action mt-3" },
                [
                  _c("span", [
                    _vm._v("Dê exemplos dos resultados esperados (opcional)"),
                  ]),
                  _c("br"),
                  _c("z-btn", {
                    staticClass: "mt-3",
                    attrs: {
                      text: "Adicionar Exemplo",
                      "x-small": "",
                      rounded: "",
                      color: "#202123",
                    },
                    on: {
                      click: function ($event) {
                        _vm.zooxAiModalDialog = true
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-plus"),
                                ]),
                                _c("span", [_vm._v("Adicionar exemplo")]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "card__body__models" }, [
            _c("div", { staticClass: "checkboxes" }, [
              _c(
                "div",
                {
                  staticClass: "d-flex align-center justify-space-between mb-2",
                },
                [
                  _c("z-checkbox", {
                    attrs: { label: "Modelos AI", "hide-details": true },
                  }),
                  _c("z-chip", {
                    attrs: { color: "#98CBE8", icon: "mdi-cash", text: "1" },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "d-flex align-center justify-space-between" },
                [
                  _c("z-checkbox", {
                    attrs: { label: "Usar seu modelo", "hide-details": true },
                  }),
                  _c("z-chip", { attrs: { text: "Grátis" } }),
                ],
                1
              ),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "card__body__ias" },
            _vm._l(_vm.ai_list, function (item) {
              return _c(
                "div",
                {
                  key: item.name,
                  staticClass: "ia",
                  class: { active: _vm.activeIA == item.id },
                  on: {
                    click: function ($event) {
                      _vm.activeIA = item.id
                    },
                  },
                },
                [
                  _c("div", { staticClass: "left" }, [
                    _c("figure"),
                    _c("span", [_vm._v(_vm._s(item.name))]),
                  ]),
                  _c("div", { staticClass: "right" }, [
                    _c(
                      "div",
                      { staticClass: "circle" },
                      [
                        _vm.activeIA == item.id
                          ? _c(
                              "v-icon",
                              { attrs: { small: "", color: "#0273c3" } },
                              [_vm._v("mdi-check")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            }),
            0
          ),
          _c("v-divider", { staticClass: "my-2" }),
          _c(
            "div",
            { staticClass: "card__body__responses" },
            [
              _c("z-select", {
                staticClass: "mb-2",
                attrs: {
                  small: "",
                  label: "Tipo de resposta",
                  items: ["a", "b", "c"],
                  "hide-details": true,
                },
              }),
              _c("z-checkbox", {
                attrs: {
                  label: "Automatização automatica",
                  "hide-details": true,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          staticStyle: { "border-radius": "10px !important" },
          attrs: { width: "900" },
          model: {
            value: _vm.templatesModalDialog,
            callback: function ($$v) {
              _vm.templatesModalDialog = $$v
            },
            expression: "templatesModalDialog",
          },
        },
        [
          _vm.templatesModalDialog
            ? _c("TemplatesAIModal", {
                on: {
                  close: function ($event) {
                    _vm.templatesModalDialog = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }