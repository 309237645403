var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "prompt" }, [
    _vm.isToEnablePrompt
      ? _c(
          "div",
          { staticClass: "prompt__inpt" },
          [
            _vm.editor
              ? _c("editor-content", {
                  staticClass: "editor",
                  attrs: { editor: _vm.editor },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    !_vm.isToEnablePrompt
      ? _c("div", { staticClass: "prompt__inpt" }, [
          _c("textarea", {
            attrs: { type: "text", "no-resize": "", "hide-details": true },
          }),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "prompt--action" },
      [_vm._t("action", null, { onClick: _vm.doAction })],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }