var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card" },
    [
      _vm._l(_vm.normal_options, function (item) {
        return _c(
          "div",
          {
            key: item.value,
            staticClass: "item",
            on: {
              click: function ($event) {
                return _vm.sendClickedInfo(item)
              },
            },
          },
          [
            _c("v-icon", { attrs: { small: "" } }, [_vm._v(_vm._s(item.icon))]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(item.text))]),
          ],
          1
        )
      }),
      _c("v-divider", { staticStyle: { "margin-bottom": "0.5rem" } }),
      _vm._l(_vm.ai_options, function (item) {
        return _c(
          "div",
          {
            key: item.value,
            staticClass: "item",
            on: {
              click: function ($event) {
                return _vm.sendClickedInfo(item)
              },
            },
          },
          [
            _c("v-icon", { attrs: { small: "" } }, [_vm._v(_vm._s(item.icon))]),
            _vm._v(" "),
            _c("span", [_vm._v(_vm._s(item.text))]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }