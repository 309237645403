var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showInformations,
            expression: "showInformations",
          },
        ],
        staticClass: "wrapper",
      },
      [
        _c(
          "div",
          { staticClass: "wrapper__card" },
          [
            _c("div", { staticClass: "wrapper__card__header" }, [
              _vm._m(0),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("z-btn", {
                    attrs: {
                      text: "Zoox A",
                      "x-small": "",
                      rounded: "",
                      color: "#202123",
                    },
                    on: {
                      click: function ($event) {
                        _vm.zooxAiModalDialog = true
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v("mdi-chip"),
                                ]),
                                _c("span", [_vm._v("Zoox AI")]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _c("z-btn", {
                    attrs: {
                      text: "Dash",
                      "x-small": "",
                      rounded: "",
                      color: "#202123",
                    },
                    on: {
                      click: function ($event) {
                        _vm.dashboadsDialog = true
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "d-flex align-center" },
                              [
                                _c("v-icon", { attrs: { "x-small": "" } }, [
                                  _vm._v(" $dashboard"),
                                ]),
                                _c("span", [_vm._v("Dashboards")]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { ref: "cardBody", staticClass: "wrapper__card__body" },
              [
                _c(
                  "v-card",
                  {
                    staticStyle: { "border-radius": "0 !important" },
                    attrs: { elevation: "1", flat: "", height: "100%" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "table-wrapper" },
                      [
                        _c("z-table", {
                          attrs: {
                            headers: _vm.headers,
                            items: _vm.infos_fake,
                            "last-fixed-column": "new_column",
                            "last-fixed-column-shadow": 3,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: `header.new_column`,
                                fn: function () {
                                  return [
                                    _c(
                                      "v-menu",
                                      {
                                        staticStyle: { "max-width": "600px" },
                                        attrs: { absolute: "", "offset-y": "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass:
                                                          "d-flex align-center",
                                                        staticStyle: {
                                                          height: "100%",
                                                          width: "100%",
                                                          cursor: "pointer",
                                                        },
                                                      },
                                                      "div",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _c("span", [
                                                      _vm._v("+ Nova coluna"),
                                                    ]),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.showMenu,
                                          callback: function ($$v) {
                                            _vm.showMenu = $$v
                                          },
                                          expression: "showMenu",
                                        },
                                      },
                                      [
                                        _c("NewColumnCard", {
                                          on: { action: _vm.setOprationToDo },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "border-radius": "10px !important" },
                attrs: { width: "500" },
                model: {
                  value: _vm.qualificationModalDialog,
                  callback: function ($$v) {
                    _vm.qualificationModalDialog = $$v
                  },
                  expression: "qualificationModalDialog",
                },
              },
              [
                _vm.qualificationModalDialog
                  ? _c("QualificationCard", {
                      on: { close: _vm.closeQualificationCard },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "border-radius": "10px !important" },
                attrs: { width: "1300" },
                model: {
                  value: _vm.formulaModalDialog,
                  callback: function ($$v) {
                    _vm.formulaModalDialog = $$v
                  },
                  expression: "formulaModalDialog",
                },
              },
              [
                _vm.formulaModalDialog
                  ? _c("FormulaCard", {
                      on: {
                        close: function ($event) {
                          _vm.formulaModalDialog = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "border-radius": "10px !important" },
                attrs: { width: "1300" },
                model: {
                  value: _vm.zooxAiModalDialog,
                  callback: function ($$v) {
                    _vm.zooxAiModalDialog = $$v
                  },
                  expression: "zooxAiModalDialog",
                },
              },
              [
                _vm.zooxAiModalDialog
                  ? _c("ZooxAICard", { on: { close: _vm.closeZooxAiModal } })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "border-radius": "10px !important" },
                attrs: { width: "400" },
                model: {
                  value: _vm.scrapperAiModalDialog,
                  callback: function ($$v) {
                    _vm.scrapperAiModalDialog = $$v
                  },
                  expression: "scrapperAiModalDialog",
                },
              },
              [
                _vm.scrapperAiModalDialog
                  ? _c("ScrapperAICard", {
                      on: {
                        close: function ($event) {
                          _vm.scrapperAiModalDialog = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                staticStyle: { "border-radius": "10px !important" },
                attrs: { width: "900" },
                model: {
                  value: _vm.dashboadsDialog,
                  callback: function ($$v) {
                    _vm.dashboadsDialog = $$v
                  },
                  expression: "dashboadsDialog",
                },
              },
              [
                _vm.dashboadsDialog
                  ? _c("DashboardsCard", {
                      on: {
                        close: function ($event) {
                          _vm.dashboadsDialog = false
                        },
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.showInformations,
            expression: "!showInformations",
          },
        ],
        staticClass: "wrapper no-data",
      },
      [_c("div", { staticClass: "wrapper__card" }, [_c("z-loading")], 1)]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("span", [_vm._v("Integração com banco de dados")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }