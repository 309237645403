<template>
  <div class="step-three">
    <div class="descriptions">
      <p>
        Defina os provedores selecionando as opções abaixo, na ordem de prioridade de
        enriquecimento
      </p>
    </div>

    <div class="drag-n-drop-zone">
      <draggable :list="providers" tag="div" handle=".handle">
        <div v-for="(item, i) in providers" :key="item.id" class="list-item">
          <v-icon class="handle" style="cursor: move"> $move_element</v-icon>
          <z-autocomplete
            :items="['a', 'b', 'c', 'd']"
            :hideDetails="true"
            :label="`${i + 1}ª  Opção`"
            small
            type="text"
          />
        </div>
      </draggable>

      <div class="new my-3">
        <span @click="addNewprovider" style="cursor: pointer">+ Adicionar novo</span>
        <v-divider class="my-3"></v-divider>
      </div>
    </div>

    <div class="schedules">
      <div class="schedules__text">
        <span>
          Você pode definir se quer <strong>agendar</strong> ou não essa qualificação.
          Caso agende, você receberá uma notificação a cada nova qualificação realizada
          nessa base.
        </span>
      </div>

      <div class="switch d-flex align-center my-3" style="gap: 0.5rem">
        <span>Automático</span> <v-switch :hide-details="true"></v-switch> Agendado
      </div>

      <div class="recurring">
        <z-autocomplete
          :items="['a', 'b', 'c', 'd']"
          :hideDetails="true"
          label="Recorrência"
          small
          type="text"
          readonly
        />
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
export default {
  components: {
    draggable,
  },
  data() {
    return {
      providers: [],
    };
  },
  methods: {
    generateRandomId() {
      const PART_1 = Math.floor(Math.random() * 245);
      const PART_2 = Date.now();
      return `${PART_1}${PART_2}`;
    },
    addNewprovider() {
      this.providers.push({ id: this.generateRandomId() });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.step-three {
  .descriptions {
    p {
      font-size: 0.9rem;
    }
  }

  .drag-n-drop-zone {
    .list-item {
      display: flex;
      align-items: center;
      gap: 0.3rem;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}
</style>
