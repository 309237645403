var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-one" }, [
    _vm._m(0),
    _c("div", { staticClass: "selector" }, [
      _c(
        "div",
        {
          staticClass: "physics",
          class: { active: _vm.activeButton == 0 },
          on: {
            click: function ($event) {
              _vm.activeButton = 0
            },
          },
        },
        [_c("span", [_vm._v("Pessoa Física")])]
      ),
      _c(
        "div",
        {
          staticClass: "legal",
          class: { active: _vm.activeButton == 1 },
          on: {
            click: function ($event) {
              _vm.activeButton = 1
            },
          },
        },
        [_c("span", [_vm._v("Pessoa Jurídica")])]
      ),
    ]),
    _vm.activeButton == 0
      ? _c(
          "div",
          { staticClass: "elements" },
          [
            _vm._l(_vm.physics_list, function (chip) {
              return [
                _c(
                  "div",
                  {
                    key: chip.id,
                    staticClass: "chip",
                    class: { active: _vm.foundInformation(chip) },
                    on: {
                      click: function ($event) {
                        return _vm.insertOrRemoveQualification(chip)
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v(_vm._s(chip.icon)),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(chip.name))]),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
    _vm.activeButton == 1
      ? _c(
          "div",
          { staticClass: "elements" },
          [
            _vm._l(_vm.legal_list, function (chip) {
              return [
                _c(
                  "div",
                  {
                    key: chip.id,
                    staticClass: "chip",
                    class: { active: _vm.foundInformation(chip) },
                    on: {
                      click: function ($event) {
                        return _vm.insertOrRemoveQualification(chip)
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [
                      _vm._v(_vm._s(chip.icon)),
                    ]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(chip.name))]),
                  ],
                  1
                ),
              ]
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descriptions" }, [
      _c("p", [_vm._v("Escolha as informações que você deseja qualificar.")]),
      _c("p", [
        _vm._v(" Sua tabela será "),
        _c("strong", [_vm._v("recarregada")]),
        _vm._v(" com as "),
        _c("strong", [_vm._v("colunas enriquecidas selecionadas abaixo.")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }