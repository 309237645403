var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "step-two" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "buttons-list" },
      [
        _vm._l(_vm.buttons_list, function (item) {
          return [
            _c(
              "div",
              {
                key: item.id,
                staticClass: "item",
                class: { active: _vm.activeButton?.id == item.id },
                on: {
                  click: function ($event) {
                    _vm.activeButton = item
                  },
                },
              },
              [
                _c("div", { staticClass: "content" }, [
                  _c("figure", [
                    _c("img", {
                      attrs: { src: item.img_path, alt: item.name },
                    }),
                  ]),
                  _c("span", [_vm._v(" " + _vm._s(item.name) + " ")]),
                ]),
                _c("div", { staticClass: "checked" }, [
                  _c(
                    "div",
                    { staticClass: "circle" },
                    [
                      _vm.activeButton?.id == item.id
                        ? _c("v-icon", { attrs: { color: "#0273c3" } }, [
                            _vm._v("$check"),
                          ])
                        : _vm._e(),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "descriptions" }, [
      _c("p", [_vm._v("Escolha a chave que será usada para a qualificação")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }