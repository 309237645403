<template>
  <div>
    <div v-show="showInformations" class="wrapper">
      <div class="wrapper__card">
        <div class="wrapper__card__header">
          <div class="left">
            <span>Integração com banco de dados</span>
          </div>
          <div class="right">
            <z-btn
              text="Zoox A"
              x-small
              rounded
              color="#202123"
              @click="zooxAiModalDialog = true"
            >
              <template #text>
                <div class="d-flex align-center">
                  <v-icon x-small>mdi-chip</v-icon>
                  <span>Zoox AI</span>
                </div>
              </template>
            </z-btn>
            <z-btn
              text="Dash"
              x-small
              rounded
              color="#202123"
              @click="dashboadsDialog = true"
            >
              <template #text>
                <div class="d-flex align-center">
                  <v-icon x-small> $dashboard</v-icon>
                  <span>Dashboards</span>
                </div>
              </template>
            </z-btn>
          </div>
          <!-- <div class="right">
            <div>
              <z-input
                v-model="searchConnection"
                placeholder="Pesquisar"
                prependIcon="$search_rounded"
                type="text"
                :hideDetails="true"
              />
            </div>

            <div>
              <z-btn
                rounded
                primary
                text="Nova conexão"
                icon="mdi-plus"
                small
                @click="goToPage('createAdditionalConnectionPage')"
              />
            </div>
            <div>
              <z-btn
                @click="goToPage('audienceIndexPage')"
                small
                rounded
                text="ir para audiência"
                icon="mdi-arrow-collapse-right"
                :iconRight="true"
              />
            </div>
          </div> -->
        </div>
        <div ref="cardBody" class="wrapper__card__body">
          <v-card elevation="1" flat height="100%" style="border-radius: 0 !important">
            <div class="table-wrapper">
              <z-table
                :headers="headers"
                :items="infos_fake"
                last-fixed-column="new_column"
                :last-fixed-column-shadow="3"
              >
                <template v-slot:[`header.new_column`]>
                  <v-menu v-model="showMenu" absolute offset-y style="max-width: 600px">
                    <template #activator="{ on, attrs }">
                      <div
                        class="d-flex align-center"
                        style="height: 100%; width: 100%; cursor: pointer"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>+ Nova coluna</span>
                      </div>
                    </template>
                    <NewColumnCard @action="setOprationToDo" />
                  </v-menu>
                </template>
              </z-table>
            </div>
          </v-card>
        </div>

        <v-dialog
          v-model="qualificationModalDialog"
          width="500"
          style="border-radius: 10px !important"
        >
          <QualificationCard
            v-if="qualificationModalDialog"
            @close="closeQualificationCard"
          />
        </v-dialog>

        <v-dialog
          v-model="formulaModalDialog"
          width="1300"
          style="border-radius: 10px !important"
        >
          <FormulaCard v-if="formulaModalDialog" @close="formulaModalDialog = false" />
        </v-dialog>

        <v-dialog
          v-model="zooxAiModalDialog"
          width="1300"
          style="border-radius: 10px !important"
        >
          <ZooxAICard v-if="zooxAiModalDialog" @close="closeZooxAiModal" />
        </v-dialog>

        <v-dialog
          v-model="scrapperAiModalDialog"
          width="400"
          style="border-radius: 10px !important"
        >
          <ScrapperAICard
            v-if="scrapperAiModalDialog"
            @close="scrapperAiModalDialog = false"
          />
        </v-dialog>

        <v-dialog
          v-model="dashboadsDialog"
          width="900"
          style="border-radius: 10px !important"
        >
          <DashboardsCard v-if="dashboadsDialog" @close="dashboadsDialog = false" />
        </v-dialog>
      </div>
    </div>
    <div v-show="!showInformations" class="wrapper no-data">
      <div class="wrapper__card">
        <z-loading />
      </div>
    </div>
  </div>
</template>

<script>
import ApiClient from "@/services/ApiClient";
import NewColumnCard from "./components/newColumnsCard.vue";
import QualificationCard from "./components/qualificationModal.vue";
import FormulaCard from "./components/formulaModal.vue";
import ZooxAICard from "./components/zooxAIModal.vue";
// SCRAPPER AI
import ScrapperAICard from "./components/scrapperAi/index.vue";
// DASHBOARDS
import DashboardsCard from "./components/dashboards/index.vue";
export default {
  components: {
    NewColumnCard,
    QualificationCard,
    FormulaCard,
    ZooxAICard,
    ScrapperAICard,
    DashboardsCard,
  },
  data() {
    return {
      dashboadsDialog: false,
      scrapperAiModalDialog: false,
      zooxAiModalDialog: false,
      qualificationModalDialog: false,
      formulaModalDialog: false,
      showMenu: false,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      sortDesc: false,
      currentUrl: null,
      connectionToDelete: null,
      connectionToUpdate: null,
      loadingRename: false,
      dialogRename: false,
      loadingDelete: false,
      dialogDelete: false,
      searchConnection: "",
      headers: [
        { text: "Nome", value: "name", sortable: false },
        { text: "Ano do evento", value: "year_event", sortable: false },
        { text: "Artista Banda", value: "artist", sortable: false },
        { text: "Estilo musical", value: "style", sortable: false },
        { text: "Região", value: "region" },
        { text: "Club", value: "club" },
        { text: "Tipo de ingresso", value: "ticket_type" },
        { text: "Recorrente em todos os anos", value: "recurring" },
        { text: "Score da recorrência", value: "score" },
        { text: "Score do club", value: "club_score" },
        { text: "+ Nova Coluna", value: "new_column" },
      ],
      infos_fake: [
        {
          id: 0,
          name: "Henry Dias",
          year_event: 2025,
          artist: "Artista de teste",
          style: "Pop",
          region: "Sudeste",
          club: "Sim",
          ticket_type: "Inteira",
          recurring: "Média",
          score: "Alta",
          club_score: "média",
          new_column: "",
        },
        {
          id: 1,
          name: "Martines Lupo",
          year_event: 2025,
          artist: "Artista de teste",
          style: "Pop",
          region: "Sudeste",
          club: "Não",
          ticket_type: "Inteira",
          recurring: "Média",
          score: "Baixa",
          club_score: "média",
          new_column: "",
        },
        {
          id: 2,
          name: "Aaron Lesbao Dumont",
          year_event: 2025,
          artist: "Artista de teste",
          style: "Pop",
          region: "Sudeste",
          club: "Sim",
          ticket_type: "Inteira",
          recurring: "Média",
          score: "Alta",
          club_score: "média",
          new_column: "",
        },
        {
          id: 3,
          name: "Henry Dias",
          year_event: 2025,
          artist: "Artista de teste",
          style: "Pop",
          region: "Sudeste",
          club: "Não",
          ticket_type: "Inteira",
          recurring: "Média",
          score: "Baixa",
          club_score: "média",
          new_column: "",
        },
        {
          id: 4,
          name: "Pedro Lopes da Silva",
          year_event: 2025,
          artist: "Artista de teste",
          style: "Pop",
          region: "Sudeste",
          club: "Sim",
          ticket_type: "Inteira",
          recurring: "Média",
          score: "Alta",
          club_score: "média",
          new_column: "",
        },
        // {
        //   id: 5,
        //   name: "Aarao Costa Serruya",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 6,
        //   name: "Aarao Costa Serruya",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 7,
        //   name: "Aarao Costa Serruya",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 8,
        //   name: "Ana Paula Peixoto",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 9,
        //   name: "Martines Lupo",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 10,
        //   name: "Pedro Lopes da Silva",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 11,
        //   name: "Aarao Costa Serruya",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 12,
        //   name: "Aaron Vicentin",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 13,
        //   name: "Fernanda Lucia",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 14,
        //   name: "Henry Dias",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 15,
        //   name: "AAmes Gustavo Leite Da Rocha",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 16,
        //   name: "AAmes Gustavo Leite Da Rocha",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 17,
        //   name: "Aaron Vicentin",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 18,
        //   name: "Henry Dias",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 19,
        //   name: "Martines Lupo",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 20,
        //   name: "Henry Dias",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 21,
        //   name: "Pedro Lopes da Silva",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 22,
        //   name: "Aarao Costa Serruya",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 23,
        //   name: "Fernanda Lucia",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 24,
        //   name: "Fernanda Lucia",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 25,
        //   name: "Aaron Lesbao Dumont",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 26,
        //   name: "Aaron Vicentin",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 27,
        //   name: "Fernanda Lucia",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 28,
        //   name: "Fernanda Lucia",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Sim",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Alta",
        //   club_score: "média",
        //   new_column: "",
        // },
        // {
        //   id: 29,
        //   name: "Aarao Costa Serruya",
        //   year_event: 2025,
        //   artist: "Artista de teste",
        //   style: "Pop",
        //   region: "Sudeste",
        //   club: "Não",
        //   ticket_type: "Inteira",
        //   recurring: "Média",
        //   score: "Baixa",
        //   club_score: "média",
        //   new_column: "",
        // },
      ],

      optionsMenu: [
        { action: "delete", text: "Texto", value: 0 },
        { action: "rename", text: "Número", value: 1 },
        { action: "document", text: "Data", value: 2 },
        { action: "log", text: "Url", value: 3 },
        { action: "log", text: "Fórmula", value: 4 },
        { action: "log", text: "Qualificar dados", value: 5 },
        { action: "log", text: "Zoox AI", value: 6 },
      ],
      optionsMenu2: [
        { action: "delete", text: "Apagar", value: 0 },
        { action: "rename", text: "Renomear", value: 1 },
        { action: "log", text: "Ver log", value: 3 },
      ],
      iconStyle: {
        width: "20px",
        height: "20px",
        cursor: "pointer",
      },
      fetchIntervalFn: null,
      showInformations: true,
      stats: {
        pending: "Pendente",
        updated: "Atualizado",
        error: "Error",
        "in-progress": "Em progresso",
      },
      http: new ApiClient(),
      recent: [],
      lastHeight: 508, // tamanho minimo para se ter dados,
    };
  },
  created() {
    // const _ = require("lodash");
    // const names = [
    //   "AAmes Gustavo Leite Da Rocha",
    //   "Aarao Costa Serruya",
    //   "Aaron Vicentin",
    //   "Pedro Lopes da Silva",
    //   "Aaron Lesbao Dumont",
    //   "Henry Dias",
    //   "Martines Lupo",
    //   "Jose Simão Ferreira",
    //   "Fernanda Lucia",
    //   "Ana Paula Peixoto",
    // ];
    // let arr = [];
    // for (let i = 0; i < 30; i++) {
    //   arr.push({
    //     id: i,
    //     name: _.sample(names),
    //     year_event: 2025,
    //     artist: "Artista de teste",
    //     style: "Pop",
    //     region: "Sudeste",
    //     club: i % 2 == 0 ? "Sim" : "Não",
    //     ticket_type: "Inteira",
    //     recurring: "Média",
    //     score: i % 2 == 0 ? "Alta" : "Baixa",
    //     club_score: "média",
    //     new_column: "",
    //   });
    // }
    // console.log(arr);
  },
  mounted() {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        this.lastWidth = width;
        this.lastHeight = height;
      }
    });

    resizeObserver.observe(this.$refs.cardBody);
  },
  computed: {
    getNumberOfElements() {
      // const minimum_height = 587;
      const minimum_height = 570;
      let items_per_page = 9;

      if (this.lastHeight && this.lastHeight < minimum_height) items_per_page = 4;

      return items_per_page;
    },
    getFavorites() {
      return !this.searchConnection
        ? this.recent.filter((item) => item.favorite == true)
        : this.filtered.filter((item) => item.favorite == true);
    },
  },
  methods: {
    closeZooxAiModal(info) {
      if (!info) {
        this.zooxAiModalDialog = false;
        return;
      }

      if (info && info?.id >= 0) {
        switch (info.id) {
          // SCRAPPER API MODAL
          case 0:
            this.zooxAiModalDialog = false;
            this.scrapperAiModalDialog = true;
            break;
        }
      } else {
        this.zooxAiModalDialog = false;
      }
    },
    onClickContextAction() {},
    closeQualificationCard(info) {
      if (!info) this.qualificationModalDialog = false;
    },
    setOprationToDo(info) {
      if (info) {
        switch (info.value) {
          case 1:
            console.log("opção 1");
            break;
          case 2:
            console.log("opção 2");
            break;
          case 3:
            console.log("opção 3");
            break;
          case 4:
            this.formulaModalDialog = true;
            break;
          case 5:
            this.qualificationModalDialog = true;
            break;
          case 6:
            console.log("opção 6");
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  color: $z-black-1;
  //   padding: $z-s-1;
  height: calc(100vh - 48px);
  &__card {
    padding: $z-s-1;
    height: 100%;
    overflow: hidden;
    display: flex;
    gap: $z-s-1;
    flex-direction: column;

    &__header {
      display: flex;
      align-items: center;
      gap: $z-s-1;
      //   margin-bottom: $z-s-2;
      flex-wrap: wrap;
      .left {
        flex: 1 1 auto;
        span {
          font-weight: 700;
          font-size: 1.1em;
          line-height: 15px;
        }
      }
      .right {
        flex: 2 1 600px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        // flex-wrap: wrap;
        gap: $z-s-1 0.5rem;

        @media (max-width: 808px) {
          justify-content: flex-start;
          & > div {
            flex: 1 1 350px;
          }
        }
      }
    }
    &__body {
      height: auto;
      .table-wrapper {
        height: 420px !important;
        overflow-y: auto;
        overflow-x: auto !important;
        @include trackScrollBar;

        @media (min-height: 700px) {
          height: 540px !important;
        }

        .no-data-table {
          text-align: center;
        }

        @media (min-width: 1550px) {
          height: auto !important;
          max-height: 70vh !important;
        }

        ::v-deep .v-data-table {
          table {
            th {
              border: 1px solid #d0d0d0;
              font-weight: 700;
              font-size: 0.65rem;
              color: #202123;
              min-width: 200px !important;
            }
            td {
              border: 1px solid #d0d0d0;
            }
          }
        }

        @media (min-width: 1550px) {
          height: auto;
          max-height: 650px;
        }
      }

      //   @media (min-width: 720px) and (max-width: 1079px) {
      //     .table-wrapper {
      //       height: 400px;
      //       max-height: 400px;
      //     }
      //   }

      //   @media (max-width: 890px) {
      //     height: auto;
      //     .table-wrapper {
      //       height: 350px;
      //       max-height: 350px;
      //     }
      //   }

      // // NOVAS TABELAS ADICIONADAS
      @media (min-width: 750px) {
        ::v-deep .v-data-table {
          table {
            th {
              &:not(:last-child) {
                min-width: 10vw !important;
              }

              &:first-child {
              }
            }
            td {
              &:first-child {
              }
            }
          }
        }
        ::v-deep .v-data-table__wrapper {
          overflow-x: initial !important;
          overflow: unset !important;
          min-width: max-content !important;
        }
      }
    }
  }
  @media (max-width: 850px) {
    height: auto;
  }
  .badge {
    // border: 1px solid grey;
    padding: 0 0.45rem;
    border-radius: 50px;
    font-size: 0.75rem;
    font-weight: 500;
    transition: 0.2s ease-in;
    display: flex;
    align-items: center;
    justify-content: center;
    // min-width: 60px;
    // max-width: 58%;

    @media (max-width: 650px) {
      max-width: 100%;
    }
    &:hover {
      // background: rgb(236, 236, 236);
    }
  }
}

.list-options {
  background: #ffffff;
  &__item {
    padding: 0.5rem;
    cursor: pointer;
    transition: 0.2s ease;
    span {
      font-weight: 500;
    }
    &:hover {
      background: #e2e2e2;
    }
  }
}

.delete-confirmation {
  padding: 1rem;
  &__title {
    text-align: center;
    font-weight: 500;
  }
  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

.no-data {
  .wrapper__card {
    height: 50vh !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .loader {
      border: 16px solid #f3f3f3;
      border-radius: 50%;
      border-top: 16px solid #3498db;
      width: 120px;
      height: 120px;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
    @-webkit-keyframes spin {
      0% {
        -webkit-transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
      }
    }

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
    span {
      font-weight: 700;
      font-size: 1.1em;
      line-height: 15px;
    }
  }
}
</style>
