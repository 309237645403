var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-card", { staticClass: "card" }, [
    _c("div", { staticClass: "card__header" }, [
      _c("div", { staticClass: "title" }, [
        _c("span", [_vm._v("Use um template de AI")]),
      ]),
      _c(
        "div",
        { staticClass: "action" },
        [
          _c(
            "v-icon",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v("mdi-close")]
          ),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "card__filter" },
      [
        _c("z-input", {
          attrs: {
            small: "",
            placeholder: "Pesquise uma ação",
            prependIcon: "$search_rounded",
            type: "text",
            hideDetails: true,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "card__body" },
      _vm._l(_vm.infos, function (item) {
        return _c("div", { key: item.id, staticClass: "card__body__items" }, [
          _c("div", { staticClass: "card__body__items__title" }, [
            _c("strong", [_vm._v(_vm._s(item.title))]),
          ]),
          _c(
            "div",
            { staticClass: "card__body__items__childs_list" },
            _vm._l(item.childs, function (child) {
              return _c(
                "div",
                {
                  key: child.name,
                  staticClass: "child",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("close")
                    },
                  },
                },
                [
                  _c("div", { staticClass: "child__title" }, [
                    _c("strong", [_vm._v(_vm._s(child.name))]),
                  ]),
                  _c("div", { staticClass: "child__description" }, [
                    _c("span", [_vm._v(_vm._s(child.description))]),
                  ]),
                ]
              )
            }),
            0
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }