<template>
  <v-card class="card">
    <div class="card__header">
      <div class="title">
        <span>Zoox AI</span>
      </div>
      <div class="action">
        <v-icon @click="$emit('close')">mdi-close</v-icon>
      </div>
    </div>

    <div class="card__descriptions">
      <span>
        <strong>A Inteligência Artificial da Zoox</strong> tem como propósito
        <strong>facilitar</strong> e <strong>obter</strong> os
        <strong>melhores dados</strong> para sua empresa. <br />
        As iniciativas de IA são assentes em <strong>3 pilares</strong> aliados a
        <strong>facilidade de uso</strong> das mesmas.
      </span>
    </div>

    <div class="card__filter">
      <span><strong>Escolha</strong> uma opção para <strong>começa.</strong></span>
      <z-input
        placeholder="Pesquise uma ação"
        prependIcon="$search_rounded"
        type="text"
        :hideDetails="true"
        small
        class="my-2"
      />
    </div>
    <div class="card__lists">
      <div v-for="item in infos" :key="item.id" class="item-info">
        <div class="item-info__title mb-3">
          <strong>{{ item.title }}</strong>
        </div>

        <div class="item-info__childs-list">
          <div
            v-for="child in item.childs"
            :key="child.name"
            class="child"
            @click="openSelectedCard(child)"
          >
            <div class="child__title">
              <span> {{ child.name }}</span>
            </div>
            <div class="child__description">
              {{ child.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      infos: [
        {
          id: 0,
          title: "Obtenção de novos dados",
          childs: [
            {
              id: 0,
              name: "Scrapper AI",
              description: "Busque qualquer informação que existe na internet",
            },
            {
              name: "APIs de qualificação",
              description: "Diversar fontes e parceiros com a melhor qualidade.",
            },
            {
              name: "Geração de leads",
              description: "Gere leads b2b e b2c",
            },
            {
              name: "Gemini ou chat GPT",
              description: "Use parâmetros de inteligência artificial.",
            },
            {
              name: "Scrapper no website",
              description: "Extraia dados de páginas web automaticamente",
            },
            {
              name: "Google API Embutida",
              description: "-",
            },
            {
              name: "Geração de conteúdo automatizada",
              description: "-",
            },
          ],
        },
        {
          id: 1,
          title: "Facilidade de uso",
          childs: [
            {
              name: "Descrição da base",
              description: "-",
            },
            {
              name: "Segmentação inteligente de audiências",
              description: "-",
            },
            {
              name: "Geração de conteúdo",
              description: "",
            },
            {
              name: "Iedntificação automatizada",
              description: "-",
            },
            {
              name: "Geração de imagens",
              description: "-",
            },
            {
              name: "Speech to text",
              description: "-",
            },
            {
              name: "Geração de gráficos",
              description: "-",
            },
            {
              name: "Cosntrução de dash com IA",
              description: "-",
            },
            {
              name: "Chat automatizado",
              description: "-",
            },
          ],
        },
      ],
    };
  },
  methods: {
    openSelectedCard(child) {
      this.$emit("close", child);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";
.card {
  padding: 1rem;
  height: 80vh;
  height: 90dvh;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 0.92rem;
  overflow: hidden;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__filter {
    max-width: 40%;
  }

  &__lists {
    border-radius: 0 !important;
    flex: 1;
    overflow-y: auto;
    @include trackScrollBar;
    padding: 0 0.3rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    border: none !important;

    .item-info {
      &__childs-list {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
        gap: 1rem;

        .child {
          cursor: pointer;
          padding: 0.8rem;
          border-radius: 10px;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

          &__title {
            span {
              font-weight: 700;
            }
          }
        }

        @media (max-width: 590px) {
          display: flex;
          flex-wrap: wrap;
          .child {
            flex: 1 1 100%;
          }
        }
      }
    }
  }
}
</style>
